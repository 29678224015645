import React, { useEffect, useState } from "react";
import {
  Subtitle,
  Select,
  Headline,
  Body,
  Caption,
} from "@metronome-industries/design-system";
import { EmptyState } from "components/EmptyState";
import {
  GetAllCreditGrantsAndLedgersQuery,
  useGetAllCreditGrantsAndLedgersQuery,
} from "types/generated-graphql/src/pages/customer/credits/queries.graphql";
import { CreditGrantList } from "./components/CreditGrantList";
import {
  CustomCreditType,
  CreditType,
  FiatCreditType,
} from "types/credit-types";
import {
  displayCreditTypeName,
  RoundedCurrency,
  USD_CREDIT_TYPE,
} from "lib/credits";
import dayjs from "dayjs";
import Decimal from "decimal.js";
import styles from "./index.module.less";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import classNames from "classnames";

export type CreditMetricProps = {
  isPrimary: boolean;
  label: string;
  amount: Decimal;
  creditType: CreditType;
};
export const CreditMetric: React.FC<CreditMetricProps> = ({
  isPrimary,
  label,
  amount,
  creditType,
}) => {
  return (
    <div
      className={
        isPrimary
          ? classNames("mr-24 mt", styles.primaryCreditInfo)
          : classNames("mt-[6px] mr-12", styles.secondaryCreditInfo)
      }
    >
      <Caption
        level={1}
        className={classNames("mb-4 normal-case", styles.caption)}
      >
        {label}
      </Caption>
      <span
        className={classNames(
          "font-medium text-default-font inline-block mr-4",
          isPrimary ? "text-xl leading-4" : "text-base leading-3",
          styles.amount
        )}
      >
        <RoundedCurrency creditType={creditType} amount={amount} hideSuffix />
      </span>
      <Body
        level={2}
        className={classNames("inline-block mb-0", styles.creditType)}
      >
        {displayCreditTypeName(creditType)}
      </Body>
    </div>
  );
};

export type IssuedCreditGrant = {
  id: string;
  costBasis: string;
  createdAt: Date;
  effectiveAt: Date;
  expiresBefore: Date;
  name: string;
  priority: string;
  reason: string | null;
  amountGranted: string;
  amountPaid: string;
  creatorId?: string;
  creatorName?: string;
  amountGrantedCreditType: FiatCreditType | CustomCreditType;
  amountPaidCreditType: FiatCreditType;
  ledger: Ledger;
  voidedAt: Date | null;
  products:
    | {
        id: string;
        name: string;
      }[]
    | null;
  invoice: {
    issued_at: Date;
  } | null;
};

type GraphqlCreditGrant = NonNullable<
  GetAllCreditGrantsAndLedgersQuery["Customer"][0]
>["CreditGrants"][0];

type GraphqlLedger = NonNullable<
  GetAllCreditGrantsAndLedgersQuery["Customer"][0]
>["ledgers"][0];

export type Ledger = {
  creditType: CreditType;
  availableBalance: Decimal;
  consumed: Decimal;
  expired: Decimal;
  totalGranted: Decimal;
  ledgerEntries: LedgerEntry[];
};

export type LedgerEntry = {
  __typename: NonNullable<
    GetAllCreditGrantsAndLedgersQuery["Customer"][0]
  >["ledgers"][0]["ledger_entries"][0]["__typename"];
  creditType: CreditType;
  grant: {
    id: string;
    name: string;
    costBasis: string;
    AmountPaidCreditType: CreditType;
    voidedAt: string | null;
  };
  amount: string;
  runningBalance: string;
  effectiveAt: Date;
  memo: string;
};

type CreditTypeSelectProps = {
  creditTypes: CreditType[];
  selectedCreditType: CreditType;
  onChange: (creditType: CreditType) => void;
};
const CreditTypeSelect: React.FC<CreditTypeSelectProps> = ({
  creditTypes,
  selectedCreditType,
  onChange,
}) => {
  const [selectedCreditTypeId, setSelectedCreditTypeId] = useState<string>(
    selectedCreditType.id
  );

  useEffect(
    () => setSelectedCreditTypeId(selectedCreditType.id),
    [selectedCreditType]
  );

  return (
    <div
      className={classNames(
        "flex items-center mr-8 -mb-4 -mt-[2px]",
        styles.creditTypeSelectContainer
      )}
    >
      <Subtitle
        level={4}
        className={classNames("mr-8", styles.pricingUnitLabel)}
      >
        Pricing unit:
      </Subtitle>
      <Select
        className={styles.creditTypeSelect}
        onChange={(creditTypeId) => {
          setSelectedCreditTypeId(creditTypeId);
          const selectedCreditType = creditTypes.find(
            (ct) => ct.id === creditTypeId
          );
          if (selectedCreditType) {
            onChange(selectedCreditType);
          }
        }}
        value={selectedCreditTypeId}
        placeholder="Choose pricing unit"
        options={creditTypes.map((ct, idx) => ({
          label: displayCreditTypeName(ct),
          value: ct.id,
        }))}
      />
    </div>
  );
};

export const parseLedger = (
  ledger: GraphqlLedger,
  availableBalanceCutoffDate: Date,
  grantId?: string
): Ledger => {
  let pendingCharges = new Decimal(0);
  let pendingGrants = new Decimal(0);
  let consumed = new Decimal(0);
  let expired = new Decimal(0);
  let totalGranted = new Decimal(0);
  let runningBalance = new Decimal(0);
  const formattedEntries = ledger.ledger_entries
    .filter((le) => (grantId ? le.credit_grant.id === grantId : true))
    .filter(
      (le) =>
        !le.credit_grant.voided_at ||
        le.__typename === "MRI_CreditGrantCreditLedgerEntry"
    )
    .map((le) => {
      const amount = new Decimal(le.amount).abs();
      if (!le.credit_grant.voided_at) {
        switch (le.__typename) {
          case "MRI_CreditGrantCreditLedgerEntry":
            totalGranted = totalGranted.add(amount);
            if (new Date(le.effective_at) > availableBalanceCutoffDate) {
              pendingGrants = pendingGrants.add(amount);
            }
            break;
          case "MRI_PendingChargeCreditLedgerEntry":
            pendingCharges = pendingCharges.add(amount);
            break;
          case "MRI_CreditExpirationCreditLedgerEntry":
            expired = expired.add(amount);
            break;
          case "MRI_CreditDeductionCreditLedgerEntry":
            consumed = consumed.add(amount);
            break;
          case "MRI_PendingCreditExpirationCreditLedgerEntry":
            // Nothing to do here. Just adding this type for completeness
            break;
          default:
        }
        runningBalance = runningBalance.add(le.amount);
      }
      return {
        __typename: le.__typename,
        creditType: ledger.credit_type,
        grant: {
          ...le.credit_grant,
          costBasis: le.credit_grant.cost_basis,
          voidedAt: le.credit_grant.voided_at,
        },
        amount: le.amount,
        runningBalance: runningBalance.toString(),
        effectiveAt: !le.credit_grant.voided_at
          ? new Date(le.effective_at)
          : new Date(le.credit_grant.voided_at),
        memo: le.memo,
      };
    });

  formattedEntries.sort((a, b) => {
    return a.effectiveAt < b.effectiveAt ? 1 : -1;
  });

  return {
    creditType: ledger.credit_type,
    availableBalance: totalGranted
      .sub(pendingGrants)
      .sub(consumed)
      .sub(expired)
      .sub(pendingCharges),
    consumed: consumed.add(pendingCharges),
    expired: expired,
    totalGranted: totalGranted,
    ledgerEntries: formattedEntries,
  };
};

const parseCreditGrant = (
  cg: GraphqlCreditGrant,
  ledger: GraphqlLedger,
  availableBalanceCutoffDate: Date
): IssuedCreditGrant => {
  return {
    ...cg,
    amountGranted: cg.amount_granted,
    amountPaid: cg.amount_paid,
    costBasis: cg.cost_basis,
    createdAt: new Date(cg.created_at),
    effectiveAt: new Date(cg.effective_at),
    expiresBefore: new Date(cg.expires_before),
    amountGrantedCreditType: cg.AmountGrantedCreditType,
    amountPaidCreditType: cg.AmountPaidCreditType as FiatCreditType,
    ledger: parseLedger(ledger, availableBalanceCutoffDate, cg.id),
    voidedAt: cg.voided_at ? new Date(cg.voided_at) : null,
    invoice: cg.invoice
      ? {
          issued_at: new Date(cg.invoice.issued_at),
        }
      : null,
  };
};

// don't display credit grants for a currency type that only has voided grants
const hasOnlyVoidedCreditGrants = (ledger: GraphqlLedger): boolean => {
  if (ledger.ledger_entries.length === 0) {
    return false;
  }
  return ledger.ledger_entries.every((entry) => entry.credit_grant.voided_at);
};

const CustomerCreditsDashboard: React.FC = () => {
  const [scrollContentHeight, setScrollContentHeight] = useState(0);

  function handleResize() {
    setScrollContentHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [selectedCreditType, setSelectedCreditType] = useState<CreditType>();

  const { data, loading, error } = useGetAllCreditGrantsAndLedgersQuery();

  useEffect(() => {
    if (loading) {
      return;
    }

    setTimeout(() => {
      const spinner = document.getElementById("spinner");
      if (spinner) {
        spinner.style.display = "none";
      }
    }, 10);
  }, [data]);

  const creditTypesById: { [k: string]: CreditType } = {};
  const ledgersByCreditTypeId: { [k: string]: GraphqlLedger } = {};
  const formattedLedgersByCreditTypeId: { [k: string]: Ledger } = {};
  const creditGrantsById: { [k: string]: IssuedCreditGrant } = {};

  // When calculating the available balance for a ledger we do not want to include
  // credit grants that start in later billing periods. availableBalanceCutoffDate
  // will either be the end of the current billing period or the end of today
  // depending on whichever is later
  const availableBalanceCutoffDate = (
    data?.Customer[0].active_invoices.invoices ?? []
  ).reduce((latestEndDate, invoice) => {
    if (invoice.__typename !== "ArrearsInvoice") {
      return latestEndDate;
    }
    const invoiceEndDate = new Date(invoice.exclusive_end_date);
    return latestEndDate.valueOf() > invoiceEndDate.valueOf()
      ? latestEndDate
      : invoiceEndDate;
  }, dayjs.utc().endOf("day").toDate());

  if (
    !loading &&
    data &&
    data.Customer[0] &&
    data.Customer[0].ledgers.length > 0
  ) {
    data.Customer[0].ledgers.forEach((ledger) => {
      if (hasOnlyVoidedCreditGrants(ledger)) return; // filter out voided grants
      creditTypesById[ledger.credit_type.id] = ledger.credit_type;
      ledgersByCreditTypeId[ledger.credit_type.id] = ledger;
      formattedLedgersByCreditTypeId[ledger.credit_type.id] = parseLedger(
        ledger,
        availableBalanceCutoffDate
      );
    });
    data.Customer[0].CreditGrants.forEach((cg) => {
      if (cg.voided_at) return; // filter out voided grants
      creditGrantsById[cg.id] = parseCreditGrant(
        cg,
        ledgersByCreditTypeId[cg.AmountGrantedCreditType.id],
        availableBalanceCutoffDate
      );
    });
  }

  const sortedCreditTypes = Object.values(creditTypesById).sort((a, b) =>
    displayCreditTypeName(a).localeCompare(displayCreditTypeName(b))
  );

  React.useEffect(() => {
    if (!selectedCreditType && sortedCreditTypes.length > 0) {
      setSelectedCreditType(sortedCreditTypes[0]);
    }
  }, [selectedCreditType, sortedCreditTypes]);

  // If we have a selectedCreditType but no ledger then the client just submitted their
  // first grant in this credit type and we are waiting for the query to be updated. So
  // we return an empty ledger in this case so we don't flash the EmptyState
  const currentLedger = selectedCreditType
    ? formattedLedgersByCreditTypeId[selectedCreditType.id] || {
        creditType: selectedCreditType,
        availableBalance: new Decimal(0),
        consumed: new Decimal(0),
        expired: new Decimal(0),
        totalGranted: new Decimal(0),
        ledgerEntries: [],
      }
    : null;

  if (loading) {
    return <div></div>;
  }

  if (error) {
    return (
      <>
        <ErrorEmptyState
          title="There was a problem loading the page. Try refreshing."
          error={error}
        />
      </>
    );
  }

  if (
    !selectedCreditType ||
    !currentLedger ||
    Object.keys(creditGrantsById).length === 0
  ) {
    return (
      <div className="mt-32">
        <EmptyState
          title="No issued credit"
          subtitle="Once you issue a credit grant, you'll see their details here."
          icon="cash"
        />
      </div>
    );
  }

  return data?.Customer.length === 0 ? (
    <div
      className="flex h-full w-full"
      style={{
        height: scrollContentHeight + "px",
      }}
    >
      <EmptyState title="No credit grants found" subtitle="" icon="receipt" />
    </div>
  ) : (
    <div
      style={{
        height: scrollContentHeight + "px",
      }}
    >
      <div
        className={classNames(
          "flex items-center justify-between",
          styles.creditsHeader
        )}
      >
        <div className={classNames("flex", styles.left)}>
          <CreditMetric
            isPrimary={true}
            label="Available credits"
            amount={currentLedger?.availableBalance ?? new Decimal(0)}
            creditType={selectedCreditType ?? USD_CREDIT_TYPE}
          />
          <CreditMetric
            isPrimary={false}
            label="Total consumed"
            amount={currentLedger?.consumed ?? new Decimal(0)}
            creditType={selectedCreditType ?? USD_CREDIT_TYPE}
          />
          <CreditMetric
            isPrimary={false}
            label="Total expired"
            amount={currentLedger?.expired ?? new Decimal(0)}
            creditType={selectedCreditType ?? USD_CREDIT_TYPE}
          />
          <CreditMetric
            isPrimary={false}
            label="Total Issued"
            amount={currentLedger?.totalGranted ?? new Decimal(0)}
            creditType={selectedCreditType ?? USD_CREDIT_TYPE}
          />
        </div>
        <div className={classNames("flex items-center", styles.right)}>
          {sortedCreditTypes.length > 1 && (
            <CreditTypeSelect
              creditTypes={sortedCreditTypes}
              selectedCreditType={selectedCreditType ?? USD_CREDIT_TYPE}
              onChange={setSelectedCreditType}
            />
          )}
        </div>
      </div>
      <hr className={classNames("mb-[15px]", styles.divider)} />
      <Headline
        level={6}
        className={classNames("mb-[15px]", styles.creditGrantsHeader)}
      >
        Your credit grants
      </Headline>
      <CreditGrantList
        issuedCreditGrants={Object.values(creditGrantsById)}
        selectedCreditType={selectedCreditType ?? USD_CREDIT_TYPE}
      />
    </div>
  );
};

export default CustomerCreditsDashboard;
